:root {
  /* Defaults */
  --background: #f2f3f7;
  --green: #1cbc96;
  --border-color: #e1e4e8;
  --white: #fff;
  --dark-color: #24292e;
  --scrollbar-color: #e1e4e8;
  --red-background: #ffebe9;
  --error-background: rgba(255, 129, 130, 0.4);
  --success-background: rgba(74, 194, 107, 0.4);
  --success-color: #4caf50;
  --red-color: #bf360c;
  --round: 25px;
  --hover-color: #f6f8fa;
  --blue-color: #316b72;
  --blue-background: rgba(29, 161, 242, 0.1);
  --color-label-border: var(--border-color);
  --theme-main-color: #07a092;
  --green-color: #259c8c;
  --green-color-hover: #1f8e8a;
  --gray-background: #f5f5f7;
  --gray-border: #e1e4e8;
  --gray-color: #24292e;
  --green-border: #259c8c;
  --green-border-light: #9ee2b8;
  --label-background: #fff5b1;
  --label-color: #259c8c;
  --label-border: #259c8c;
  --text-size-default: 14px;
  --background-color-primary: var(--white);
  --background-color-secondary: #f9f9f9;
  --background-color-tertiary: #f2f2f2;
  --background-color-info: #e7f0ff;
  --background-color-brand: #fff1eb;
  --background-color-error: #ffebe7;
  --background-color-success: #e5fff1;
  --text-color-success: #259c8c;
  --background-color-warning: #fff9e0;
  --border-color-secondary: #e1e4e8;

  /** Borders **/
  --border-green: #9ee2b8;
  --success-color: #4caf50;
  --success-bg-color: #e8f5e9;
  --error-color: #f44336;
  --error-bg-color: #ffebee;
  --warning-color: #ff9800;
  --warning-bg-color: #fff3e0;
  --info-color: #2196f3;
  --info-bg-color: #e3f2fd;
  --grey-text-color: #757575;
}

* {
  box-sizing: border-box;
  line-height: 1.5;
}

html {
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  height: 100%;
}

body {
  background-color: var(--background);
  margin: 0;
  color: #333;
  letter-spacing: 0.022em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  margin: 0;
  padding: 0;
  /* user-select: none;
  -moz-user-select: none; */
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
main,
.home-section {
  background-color: var(--background);
  height: 100%;
}

.home-section {
  margin: 0 auto;
}

a {
  color: var(--green-color);
  text-decoration: none;
  outline: none;
}

.error {
  color: var(--error-color);
  background-color: var(--error-bg-color);
}

.error-text {
  color: var(--error-color);
}

.success {
  color: var(--success-color);
  background-color: var(--success-bg-color);
}

.warning {
  color: var(--warning-color);
  background-color: var(--warning-bg-color);
}

.info {
  color: var(--info-color);
  background-color: var(--info-bg-color);
}

.bg-yellow-dark {
  background-color: var(--yellow-700);
}

.capitalize {
  text-transform: capitalize;
}

.no-result {
  text-align: center;
  padding: 20px;
  width: 100%;
}

.overflow {
  background-color: var(--white);
  overflow: auto;
}

.icon-error {
  background-color: var(--error-background);
  color: var(--red-color);
}

.icon-success {
  background-color: var(--success-background);
  color: var(--success-color);
}

.is-empty {
  margin: 0 auto;
  padding: 25px 0;
}

.is-empty-title {
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.is-empty-img {
  display: block;
  margin: 0 auto;
  width: 100px;
}

.select-icon {
  display: flex;
  align-items: center;
  justify-self: center;
  cursor: pointer;
  padding: 0 10px;
  padding-right: 14px;
}

.inner-nav-bottom {
  text-align: center;
}

.avatar {
  border: 1px solid var(--border-color);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 33px;
  width: 33px;
}

.avatar::before {
  color: var(--blue-400);
  font-weight: 600;
  line-height: 30px;
}

.left-h1 {
  padding: 0;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  text-transform: capitalize;
  width: 100%;
}

.selected {
  font-weight: 600;
  color: #259c8c !important;
}

button,
select {
  text-transform: none;
}

.inner-paylinks {
  background-color: transparent;
  font-size: 13px;
  user-select: none;
  -moz-user-select: none;
}

.state-label {
  display: flex;
  color: black !important;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  align-content: baseline;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 2px 10px;
  border: 1px solid #f9c513;
}

.reclaim-btn {
  display: flex;
  color: black !important;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  align-content: baseline;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 4px 10px;
  outline: 0;
  background-color: var(--background);
  border: 1px solid var(--blue-200);
}

.reclaim-btn:hover {
  background-color: var(--background);
}

.pending {
  background-color: rgb(255, 234, 127);
  border: 1px solid #f9c513;
}

.note-color {
  color: var(--green-color);
}

.page-desc {
  padding: 0 20px;
  margin-top: 20px;
  font-size: 15px;
}

.item-header {
  background-color: transparent;
  border-bottom: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 22px;
  padding-right: 16px;
  text-transform: uppercase;
}

.item-header-left {
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  width: 90%;
}

.item-header-right {
  text-align: right;
  width: 15%;
}

.inner-paylink {
  border-top: 1px solid var(--border-color-secondary);
  height: auto;
  max-height: 450px;
  overflow: auto;
}

.flex-links {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.links-left {
  display: flex;
  align-items: center;
  width: 90%;
}

.links-right {
  text-align: right;
  width: 10%;
}

.links-footer {
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 8px;
}

.reclaim-link {
  background-color: var(--white);
  display: flex;
  color: black;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  align-content: baseline;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 6px 20px;
  border: 1px solid var(--border-color);
}

.reclaim-link:hover {
  background-color: var(--green-color);
  border-color: var(--green-color);
  color: var(--white);
  transition: all 0.15s ease 0s;
}

.voucher-loader {
  margin: 15px auto;
}

.link-date {
  font-size: 10.5px;
}

.nav-link {
  display: block;
}

small {
  font-size: 14px;
}

.icons:hover {
  color: var(--green-color);
}

.link-items {
  overflow: auto;
}

.mobile-user-id {
  display: flex;
  right: 0;
  float: right;
  align-items: center;
}

.link-item {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  margin: 15px;
  border-radius: 10px;
}

.no-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 15px 20px;
  text-align: center;
  font-size: 17px;
  margin: 20px auto;
}

.token-label-small {
  margin-left: 5px;
  background-color: var(--label-background);
  padding: 2px 10px;
  border-radius: 50px;
  text-transform: uppercase;
  padding-top: 4px;
  font-size: 9px;
}

.flex-row {
  display: flex;
  align-items: center;
}

.check {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;
  margin-bottom: 8px;
}

.checkbox {
  cursor: pointer;
  margin: 0;
  margin-right: 5px;
  width: auto;
}

label {
  color: #222;
  width: 100%;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: wait;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 3.5px;
  border-radius: 25px;
  font-size: 13.5px;
}

.btn:disabled {
  opacity: 0.6;
  cursor: wait;
}

.btn-primary {
  background-color: var(--green-color);
  border: 1px solid var(--green-color);
  color: var(--white);
}

.btn-secondary {
  background-color: var(--white);
  border: 1px solid var(--green-color);
  color: var(--green-color);
}

.btn-cancel {
  background-color: var(--white);
  border: 1px solid var(--red-color);
  color: var(--red-color);
}

.btn-primary:hover,
.btn-secondary:hover,
.btn-cancel:hover {
  transition: all 0.15s ease 0s;
}

.btn-primary svg {
  margin-right: -3px;
}

.btn-secondary svg,
.btn-cancel svg {
  margin-left: -3px;
}

.btn-cancel:hover {
  background-color: var(--red-color);
  color: var(--white);
}

.btn-secondary:hover {
  background-color: var(--green-color);
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--white);
  color: var(--green-color);
}

.flex-col-centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.lockpin {
  margin-left: 5px;
  font-weight: 600;
  font-size: 10px;
}

.unpaid {
  color: var(--blue-300);
  font-size: 11px;
}

.control-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form.control input {
  font-size: 15px !important;
}

.control {
  display: flex;
  flex-direction: row;
  border: 1px solid #c8e1ff;
  border-radius: 4px;
  width: 90%;
}

.control-round-btn {
  border-radius: 50%;
  border: 1px solid #c8e1ff;
  color: var(--gray-color);
  cursor: pointer;
  margin-left: 7px;
  text-align: center;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  align-content: center;
}

.control-round-btn:hover {
  border: 0.1px solid var(--red-color);
  color: var(--red-color);
  background-color: var(--error-background);
}

.control-round-btn span {
  width: 100%;
}

.control-left {
  border: 0;
  border-radius: 0;
  border-right: 1px solid #c8e1ff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 13px;
  text-align: left;
  outline: 0;
  padding: 5px 10px;
  width: 70%;
  margin: 0;
}

.control-right {
  border: 0;
  margin: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 30%;
  display: inline-block;
  margin: 0 auto;
  background-color: var(--background);
  font-size: 13px;
  font-weight: 600;
}

.redeem-flex-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.flex-btns-left {
  margin-right: 5px;
  width: 50%;
}

.flex-btns-right {
  margin-left: 5px;
  width: 50%;
}

.link-control {
  display: flex;
  flex-direction: row;
  border: 1px solid #c8e1ff;
  border-radius: 4px;
  font-size: 10px;
}

.link-control input {
  font-size: 13px !important;
}

.back-nav {
  padding-left: 10px;
}

.load-page {
  margin: 0;
  display: flex;
  position: absolute;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.load-page-image {
  width: 100px;
  height: 100px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.load-page-image {
  width: 160px;
}

.AnimatedEllipsis {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

.account-select {
  border: 1px solid #d0e5fd;
  border-radius: 5px;
  font-size: 17px;
  margin: 10px auto;
  padding: 6px;
  width: 100px;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right;
}

.payment-note {
  margin: 5px auto;
}

/* input:invalid {
    color: #d73a49;
  } */

.invalid {
  display: block;
  margin-bottom: 15px;
}

.flex-footer {
  display: none;
  padding: 10px 0;
}

.flex-footer-item {
  color: rgb(101, 119, 134);
  display: inline;
  font-size: 14px;
}

.m-left {
  margin-left: 5px;
}

.flex-footer-item:hover {
  transition: all 0.15s ease 0s;
}

.side {
  border-right: 0.8px solid var(--border-color-primary);
  display: flex;
  flex-direction: column;
  z-index: 5 !important;
  padding: 0 !important;
  width: 80% !important;
  align-items: stretch;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.side-name {
  color: var(--gray-color);
  font-weight: 600;
}

.side-email {
  color: rgb(101, 119, 134);
  font-size: 13.5px;
  margin: 2px auto;
}

.side-link {
  display: flex;
  align-items: center;
  flex-direction: revert;
  border-bottom: 0.8px solid var(--border-color-primary);
  color: var(--gray-color);
  padding: 12px 20px;
}

.side-link-text {
  margin: 0 auto;
  margin-left: 8px;
}

.side-settings {
  display: flex;
  align-items: center;
  bottom: 0;
  position: fixed;
  border-top: 0.8px solid var(--border-color-primary);
  padding: 8px 0;
  width: 80%;
}

.side-close {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  width: 15%;
}

.transactions-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  position: relative;
}

.trans-pending-label {
  display: flex;
  align-items: center;
}

.trans-pending-label span {
  margin-left: 4px;
}

.pointer-box {
  border-radius: 4px !important;
  margin: 8px 0;
  padding: 5px 12px;
  text-align: center;
}

.type-label {
  color: #000 !important;
  background-color: var(--blue-100);
  font-size: 11px;
  font-weight: 600;
  padding: 0 10px 2px;
  border-radius: 2px;
  width: 60px;
  text-align: center;
  margin: 5px 0;
}

.c-check {
  color: rgb(40, 167, 69);
}

.c-close {
  color: #f66a0a;
}

.c-reject {
  color: rgb(215, 58, 73);
}

.border-lr {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.middle-header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  margin: 0 auto;
  width: 100%;
}

.middle-header-item {
  color: #000;
  cursor: pointer;
  text-align: center;
  padding: 7px 0;
  width: 100%;
}

.middle-header-item:hover {
  color: var(--green-color);
}

.btn-disbled {
  color: #959da5;
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
}

.anim-ellipsis {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

.swal-footer {
  text-align: center;
}

.swal-button {
  padding: 8px 24px;
}

.swal-text {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("assets/images/general/calendar.svg") no-repeat;
  border-width: thin;
  cursor: pointer;
}

input {
  border: 1px solid var(--border-color);
  padding: 10px 12px;
  cursor: pointer;
  outline: none;
}

input[type="radio"] {
  cursor: pointer;
}

.input-error {
  color: var(--error-color);
  font-size: 13px;
  margin-top: 5px;
}

button {
  display: flex;
  align-items: center;
  gap: 5px;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

::placeholder {
  color: #494949;
}

::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* track */

::-webkit-scrollbar-track {
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

/* handle */

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: var(--scrollbar-color);
  margin-right: 4px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}

.mobile-only {
  display: none;
}

.subtitle {
  font-size: 1rem;
  text-align: center;
  margin: 20px auto;
}

.checked-color {
  color: var(--green-color);
}

.label {
  border-radius: 5px;
  color: var(--dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 90px;
  font-size: 10px;
}

.voucher-date-created {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  color: var(--dark-color);
}

input,
select,
textarea {
  border-radius: 0.25rem;
  padding: 0.9rem 0.75rem;
  outline: none;
  font-size: 15px;
  width: 100%;
}

input,
select,
textarea:focus {
  border-color: #44bb97;
}

.fade-in {
  animation: fadeInAnimation ease 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.float-in {
  animation: floatInAnimation ease 0.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floatInAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .default-background-mobile,
  .categories-section {
    background-color: var(--background);
  }

  .item-header {
    padding: 15px 20px;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--background);
  }
}

@media (min-width: 800px) {
  .transactions-flex {
    max-width: 97.8%;
  }

  .flex-right {
    padding: 20px;
  }

  .flex-container {
    background-color: var(--white);
    border-radius: 7px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: normal;
  }

  .logout-desktop {
    border: 0;
  }

  .no-links {
    font-size: 14px;
  }

  .link-items {
    /* background-color: #fafafa; */
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .hide-mobile {
    display: none;
  }
}

@media (min-width: 1050px) {
  .flex-right {
    border-right: 0px solid var(--border-color-primary);
  }
}

@media (min-width: 1200px) {
  .home-section,
  .page-view,
  .buy-page,
  .account-page,
  .update-account {
    margin: 0 auto;
    max-width: 1350px;
    width: 100%;
  }
}
