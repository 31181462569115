.tb-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 20px;
}

.tb-pagination-btns {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tb-pagination-prev-btn,
.tb-pagination-next-btn {
  border: 1px solid var(--gray);
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 15px;
}

.tb-pagination-btn-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  cursor: pointer;
}

.tb-pagination-prev-btn:hover,
.tb-pagination-next-btn:hover,
.tb-pagination-btn-item:hover {
  background-color: var(--green);
  color: #fff;
}

.tb-pagination-btn-item-selected {
  color: #fff;
  background-color: var(--green);
}

.pagination-btn:disabled:hover {
  opacity: 0.6;
}
