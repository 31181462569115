.toast {
  display: flex;
  color: #1b1f23;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px #dadce0, 0 1px 100px rgb(27 31 35 / 15%);
  letter-spacing: 0.75px;
  font-size: 14px;
  width: fit-content;
  margin: 5px auto;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 80px;
  z-index: 1000;
}

.toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  flex-shrink: 0;
  color: var(--white);
  fill: #fff;
  background-color: #000;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.toast-content {
  padding: 10px 16px;
  width: 90%;
}

.toast-dismissButton {
  cursor: pointer;
  padding: 7px 16px;
  background-color: transparent;
  border: 0;
  border-left: 1px solid var(--border-color);
}

.p-1 {
  margin: 0 auto;
}

.toast-dismissButton:focus,
.toast-dismissButton:hover {
  color: #586069;
  outline: none;
}

.toast-dismissButton:active {
  color: #959da5;
}

.toast--error .toast-icon {
  background-color: #d73a49;
}

.toast--warning .toast-icon {
  color: #24292e;
  background-color: #f9c513;
}

.toast--success .toast-icon {
  background-color: #28a745;
}

.toast--loading .toast-icon {
  background-color: #586069;
}

.toast--animateIn {
  animation: toast--animateIn 0.18s cubic-bezier(0.22, 0.61, 0.36, 1) backwards;
}

@keyframes toast--animateIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.toast--animateOut {
  animation: toast--animateOut 0.18s cubic-bezier(0.55, 0.06, 0.68, 0.19)
    forwards;
}

@keyframes toast--animateOut {
  100% {
    pointer-events: none;
    opacity: 0;
    transform: translateY(100%);
  }
}

.toast--spinner {
  animation: toast--spinner 1000ms linear infinite;
}

@keyframes toast--spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
