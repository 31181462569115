.page-view {
  height: 100%;
}

.wallet-nav-item {
  color: var(--gray-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color-secondary);
}

.wallet-nav-item:last-child {
  border-bottom: 0;
}

.account {
  border-bottom: 0;
}

.wallet-nav-item-left {
  font-size: 1em;
  width: 90%;
  text-align: left;
  font-weight: 500;
}

.wallet-nav-item-right {
  width: 10%;
  text-align: right;
}
