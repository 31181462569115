.purchase-page {
  margin: 0 auto;
  width: 100%;
  padding: 0;
}

.purchase-header-back {
  padding: 20px;
  margin: 0 auto;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
}

.purchase-header-back-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100px;
  font-weight: 500;
  user-select: none;
}

.purchase-content {
  border-radius: 10px;
  margin: 50px auto;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding-bottom: 20px;
}

.purchase-title {
  color: var(--gray-color);
  font-size: 24px;
  text-align: center;
  margin: 10px auto;
  margin-bottom: 25px;
  padding: 20px;
  user-select: none;
  border-bottom: 1px solid var(--border-color);
}

.purchase-loading {
  margin: 0 auto;
  text-align: center;
}

.purchase-content-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
}

.purchase-content-subtitle {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.purchase-message {
  font-size: 17px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.purchase-content-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  color: #fff;
}

.cancel-content-icon {
  text-align: center;
}

.purchase-content-voucher {
  text-align: center;
}

.purchase-content-voucher-text {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.purchase-retry-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  cursor: pointer;
  max-width: 120px;
  user-select: none;
}

.purchase-content-voucher-code {
  color: var(--green-color);
  margin: 0 7px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .purchase-content {
    box-shadow: none;
    border-radius: 0;
  }
}
