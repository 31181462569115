.alert-message {
  display: flex;
  align-items: center;
  margin: 0 auto;
  font-size: 13.5px;
  padding: 8px 10px;
  font-weight: 400;
  gap: 8px;
}

.alert-message-text {
  margin-left: 5px;
  width: 100%;
}

@media (max-width: 768px) {
  .alert-message {
    text-align: left;
    width: 100%;
    font-size: 13.5px;
  }
}
