.buy {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  padding: 20px 25px;
  border-radius: 10px;
  border: 1px solid var(--border-color-secondary);
}

.buy-title,
.buy-voucher,
.buy-voucher-btn,
.buy-vouchers-loading,
.form-footer-cta,
.buy-payment-options,
.buy-payment-option,
.buy-payment-option-box,
.buy-pre-order-voucher,
.buy-pre-order-title,
.buy-payment-cta,
.buy-payment-verifying,
.buy-payment-verifying-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.buy-header {
  margin-bottom: 15px;
}

.buy-title {
  font-size: 18px;
  gap: 8px;
  margin-bottom: 15px;
  user-select: none;
}

.buy-title-categ {
  color: var(--grey-text-color);
  font-weight: 500;
}

.buy-vouchers {
  font-size: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.buy-voucher {
  border-radius: 5px;
  border: 1px solid var(--border-green);
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  flex: 1;
}

.buy-voucher-title {
  flex: 1;
}

.buy-voucher-btn {
  cursor: pointer;
  font-size: 11px;
  padding: 5px 10px;
  border-radius: 3px;
  text-transform: uppercase;
  animation: ease-in-out 0.2s;
}

.buy-voucher-btn svg {
  margin-right: -4px;
  gap: 3px;
}

.buy-voucher-btn:hover {
  background-color: var(--green-color);
  color: var(--white);
}

.buy-vouchers-loading {
  gap: 8px;
  padding: 0 10px;
  font-size: 14px;
}

.buy-vouchers-loading-text {
  flex: 1;
}

.input-field {
  margin-bottom: 9px;
}

.input-field:last-child {
  margin-bottom: 0;
}

.input {
  padding: 9px 10px;
  width: 100%;
}

.input-label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 9px;
}

.input-label-info {
  font-size: 13px;
  font-weight: 400;
  color: #757579;
}

.form-footer-cta {
  margin-top: 12px;
  justify-content: space-between;
  width: 100%;
}

.buy-payment-options {
  align-items: flex-start;
  flex-direction: column;

  gap: 10px;
  width: 100%;
}

.buy-payment-option-label {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.buy-payment-option {
  border-radius: 5px;
  border: 1px solid var(--border-color-secondary);
  cursor: pointer;
  transition: ease-in-out 0.2s;
  flex: 1;
  gap: 8px;
  align-items: flex-start;
  font-size: 15px;
  padding: 15px;
  width: 100%;
}

.buy-payment-option-box {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--border-color-secondary);
  justify-content: center;
}

.buy-payment-option-box-active {
  background-color: var(--green-color);
  color: var(--white);
}

.buy-payment-option-info,
.buy-pre-order-title,
.buy-pre-order-voucher {
  font-size: 14px;
  color: var(--grey-text-color);
}

.buy-payment-option-info {
  margin: 5px 0;
}

.mpesa-input {
  padding: 10px 12px;
  margin-top: 8px;
}

.buy-pre-order-title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  text-decoration: underline;
}

.buy-pre-order-voucher {
  gap: 10px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-color-secondary);
}

.buy-pre-order-voucher-left {
  border-right: 1px solid var(--border-color-secondary);
  color: var(--green-color);
  width: 80px;
  padding: 10px 0;
  font-weight: 500;
}

.buy-pre-order-voucher-right {
  flex: 1;
  text-transform: capitalize;
}

.buy-vouchers-empty {
  font-size: 14px;
  color: var(--grey-text-color);
  padding: 12px 15px;
  border: 1px solid var(--warning-bg-color);
  border-radius: 3px;
  background-color: var(--warning-bg-color);
  color: var(--warning-color);
  width: 100%;
}

.buy-payment-processing-title {
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
}

.buy-payment-cta {
  justify-content: right;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  margin-top: 18px;
}

.buy-payment-verifying {
  justify-content: center;
  flex-direction: column;
}

.buy-payment-verifying {
  padding: 10px 0;
}

.buy-payment-verifying-info {
  font-size: 15px;
  color: var(--grey-text-color);
  padding: 10px 0;
}

.buy-payment-verifying-text {
  font-size: 14px;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .buy {
    border: 0;
  }

  .buy-voucher {
    flex-direction: row;
    align-items: flex-start;
  }

  .buy-voucher-title {
    font-size: 14px;
    flex: 1;
  }
}
