.categories-section {
  background-color: var(--white);
}

.categories-container {
  background-color: var(--white);
  margin: 0 auto;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 25px;
  width: 100%;
  max-width: 450px;
}

.categories-container .item {
  color: var(--white);
  font-size: 30px;
  background-color: transparent;
  max-width: 300px;
}

.search-bar {
  display: none;
  float: right;
  margin: 30px;
  padding: 10px;
}

.category-card {
  height: 100%;
  background-color: transparent;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  width: 100%;
}

.card-header {
  background-color: var(--white);
  border: 1px solid var(--border-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  margin: 0 auto;
}

.card-header:hover {
  color: var(--green-color);
  border: 1px solid var(--green-border-light);
  transition: all 0.15s ease 0s;
}

.category-logo {
  display: block;
  margin: 0 auto;
  width: 60px;
  height: 60px;
}

.card-content {
  display: block;
  /* color: #718096; */
  padding: 12px 0;
  text-align: center;
  /* border-bottom: 1px solid #e2e8f0 !important; */
  margin: 0 auto;
  font-size: 15px;
  text-decoration: none;
  font-weight: var(--font-weight-medium);
}

.card-content:hover {
  color: #259c8c !important;
}

.card-content h1 {
  color: #718096;
  font-weight: 600;
  font-size: 15px;
}

.card-button {
  cursor: pointer;
  text-align: center;
  margin: auto;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  display: none;
}

.card-button h1 {
  width: fit-content;
  background-color: #316b72;
  color: var(--white);
  font-size: 10px;
  padding: 8px;
  border-radius: 7px;
  font-weight: normal;
}

@media screen and (max-width: 260px) {
  .category-logo {
    width: 60px !important;
    height: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .home-section {
    background-color: var(--white);
  }

  .categories-container {
    background: var(--background);
    /* display: flex; */
    overflow: auto;
    box-sizing: border-box;
    grid-column-gap: 0;
    margin: 0;
    grid-gap: 15px;
    padding: 0 10px;
    margin: 10px auto;
  }

  .category-card {
    max-width: 175px;
  }

  .card-header {
    height: 60px;
    width: 60px;
  }

  .category-logo {
    height: 40px;
    width: 40px;
  }

  .card-content {
    font-size: 13px;
  }
}

@media screen and (max-width: 280px) {
  .categories-container {
    background: var(--background);
    /* display: flex; */
    overflow: auto;
    box-sizing: border-box;
    grid-column-gap: 0;
    margin: 0;
    grid-gap: 15px;
    padding: 0 10px;
    margin: 10px auto;
  }

  .category-card {
    max-width: 175px;
  }
}
