h2 {
  pointer-events: none;
}

.voucher {
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white);
}
.card {
  background: #000;
  position: relative;
  border: 0px solid var(--border-color-primary);
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin-bottom: 8px;
}
.voucher .card:before {
  content: "";
  z-index: 99;
  position: absolute;
  top: -10px;
  /* left: 32px; */
  left: 15px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--white);
}
.voucher .card:after {
  content: "";
  z-index: 99;
  position: absolute;
  bottom: -10px;
  /* left: 32px; */
  left: 15px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--white);
}
.voucher-dots {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  position: absolute;
  top: 0;
  text-align: center;
  margin: 0;
  width: 46px;
}
.voucher-dots span {
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 2px;
  background-color: var(--white);
}
.voucher .card h2 {
  z-index: 99;
  bottom: 0;
  color: var(--white);
  margin: 0;
  margin-left: 45px;
  width: 120px;
  margin-top: 8px;
  padding: 5px 0;
  font-size: 17px;
  font-weight: 700;
}
.voucher .card .fa-arrow-right {
  z-index: 100;
  position: absolute;
  right: 75px;
  bottom: 25px;
  font-size: 40px;
  cursor: pointer;
}
.voucher .card p {
  position: absolute;
  top: 20px;
  right: 50px;
  color: #000;
  font-size: 13px;
  letter-spacing: 1px;
  writing-mode: vertical-lr;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 600;
  z-index: 99;
}

.voucher .card {
  z-index: 100;
  object-fit: cover;
  background-size: cover;
  width: 100%;
  height: 100px;
}

.voucher .card button {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 30px;
  height: 30px;
  background-color: #da4d1d;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  mix-blend-mode: hard-light;
}

.voucher .card:hover button {
  transform: scale(5.5);
}

.voucher .card:hover p {
  color: var(--white);
}

.voucher .card:hover {
  filter: grayscale(0);
}

.voucher .card2 {
  background-color: #4e98a3;
}

.voucher .card2 button {
  background-color: #000;
}

.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}

.voucher-arrow {
  cursor: pointer;
  display: flex;
  color: #000;
  align-items: center;
  right: 17px;
  position: absolute;
  z-index: 1000;
  top: 10px;
}

@media screen and (max-width: 736px) {
  .vouchers-container {
    display: block !important;
  }

  .voucher .card h2 {
    max-width: 180px;
    text-transform: uppercase;
  }

  .voucher .card p {
    right: 50px;
  }
}
