.purchase-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  inset: 0px;
  overflow: auto;
  z-index: 1000;
  padding: 0;
}

.purchase-list {
  display: none;
  bottom: 0;
  position: fixed;
  background-color: var(--white);
  height: calc(100% - 170px);
  margin: 0 auto;
  z-index: 1000;
  width: 100%;
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.purchase-header {
  border: 0;
  border-bottom: 1px solid var(--green-color);
  display: flex;
  border-top: 1px solid var(--green-color);
  align-items: center;
  padding: 15px;
  width: 100%;
  position: sticky;
  color: var(--white);
  top: 0;
  background-color: var(--green-color);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.purchase-title {
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.purchase-icon {
  cursor: pointer;
}

.voucher-list-items {
  background-color: var(--background);
  overflow: auto;
  height: calc(100% - 59px);
}

.voucher-info {
  font-size: 14px;
}

.voucher-info-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  padding: 7px 10px;
}

.voucher-token-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-top: 0;
  font-size: 16px;
}

.voucher-info-back {
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  float: right;
  cursor: pointer;
}

.voucher-tag-label {
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 10px 0;
}

.voucher-tag-label-text {
  padding: 0 5px;
}

.voucher-info-back:hover {
  color: #316b72;
}

.voucher-value {
  color: var(--green-color);
  width: 100%;
}

.note-desc {
  padding: 20px 16px;
  border-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 100%;
}

.note-desc-text {
  color: #222;
  padding: 15px;
  background: #e2f1f3;
  margin: 15px auto;
  margin-top: 0;
  border-radius: 5px;
}

.link-item:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
@media (max-width: 768px) {
  .purchase-overlay {
    display: block;
  }
  .purchase-list {
    display: block;
  }
}
