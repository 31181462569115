.nav {
  background: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 2;
  border-top: 1px solid var(--border-color);
}

.nav-item {
  position: relative;
  width: 100%;
  font-size: 19px;
}

.select-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-item > a,
.nav-item > span a {
  font-size: inherit;
  align-items: center;
  color: var(--gray-color);
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 800px) {
  .nav {
    display: none;
    height: 100%;
    width: 55px;
    margin-right: calc(50% - 300px);
  }
}

@media screen and (max-width: 799px) {
  .nav {
    display: block;
    letter-spacing: 2px;
  }
}

@media screen and (min-width: 800px) {
  .nav-item > a,
  .nav-item > span a {
    padding: 20px 0;
  }
}

.nav-item[selected="true"] > a,
.nav-item[selected="true"] > span a {
  color: #316b72;
  background-color: var(--white);
}

.nav-item[selected="true"] > span svg {
  fill: #316b72;
}

a:hover {
  color: #316b72 !important;
  text-decoration: none;
}

.nav-item[selected="true"] > a > i,
.nav-item[selected="true"] > span a > i {
  opacity: 1;
}

@media screen and (min-width: 800px) {
  .nav {
    border-right: 1px solid var(--border-color);
  }
  .nav-item:hover > a,
  .nav-item:hover > span a {
    border: none;
  }
}

.nav-item-label {
  font-size: 15px;
  margin-top: 4px;
}

@media screen and (min-width: 600px) {
  .nav-item-label {
    margin-top: 11px;
  }
}

.hamburgerButton {
  border-radius: 50%;
  color: var(--white);
  display: block;
  margin: 16px;
  padding: 8px;
  transition: background-color 0.2s ease-in-out;
}

.hamburgerButton:focus {
  background-color: #f1f3f4;
}

.compactNav {
  max-width: 300px;
}

.navContent {
  background-color: var(--white);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  z-index: 99;
}

.navContentCompact {
  background-color: var(--white);
  bottom: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: all 0.2s ease-in-out;
  width: calc(100% - 56px);
}

.navContentCompact .navLogo {
  border-bottom: 1px solid var(border-color);
  padding: 15px;
}

.navContentCompact .navLogo > a,
.navContentCompact .navLogo > span a {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.navContentCompact .navContentLeft {
  height: 100%;
}

.navContentCompact .navList {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 24px 0 0;
  padding: 0;
}

.navContentCompact .nav-item {
  list-style-type: none;
  position: relative;
  width: 100%;
}

.navContentCompact .nav-item > a,
.navContentCompact .nav-item > span a {
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 0 36px 36px 0;
  color: #3c4043;
  cursor: pointer;
  display: block;
  letter-spacing: 0.25px;
  padding: 16px 0 16px 20px;
  transition: all 0.2s ease-in-out;
}

.navContentCompact .nav-item[selected="true"] > a,
.navContentCompact .nav-item[selected="true"] > span a {
  color: #1a73e8;
  font-weight: var(--font-weight-bold);
  background-color: rgba(26, 115, 232, 0.12);
}

@media (hover: hover) {
  .navContentCompact .nav-item:hover > a,
  .navContentCompact .nav-item:hover > span a {
    color: #1a73e8;
  }
  .navContentCompact .nav-item:hover:after {
    width: 100%;
  }
}

.navList {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0 !important;
  padding-top: 8px !important;
  box-sizing: border-box;
}

ol.reset,
ul.reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

p.reset {
  margin: 0;
}

label {
  float: left;
}

.nav-logo {
  width: 36px;
}

@media screen and (min-width: 800px) {
  .navList {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: end;
    height: calc(100% - 80px);
  }
  .nav {
    border-top: 0px solid var(--border-color-primary);
  }
}
