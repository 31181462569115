.slider-items {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 15px;
  max-width: 550px;
  overflow: scroll;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border-radius: 15px;
}

.slider-title {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.slider-item {
  background-color: var(--background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  transition: all 0.6s ease;
  border-radius: 15px;
  height: 115px;
  min-width: 200px;
  position: relative;
  flex-grow: 1;
  user-select: none;
}

.slider-item img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.slider-item-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: 15px;
  color: var(--white);
  font-weight: 600;
  height: 115px;
  min-width: 200px;
}

.slider-item-box:hover {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.slider-item-box-label {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

.slider-items::-webkit-scrollbar {
  display: none;
}

/** Media Queries */
@media screen and (max-width: 540px) {
  .slider-items {
    border-radius: 0;
    padding: 0 20px;
  }

  .slider-item {
    background-color: var(--white);
  }
}

@media screen and (max-width: 768px) {
  .slider-item,
  .slider-item-box {
    height: 100px;
    min-width: 175px;
  }
}
