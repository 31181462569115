.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 0 26px;
  background: #fff;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1 1;
  max-width: 400px;
}

.search-input {
  flex: 1 1;
  max-width: 100%;
  border: none;
  outline: none;
  padding: 10px 15px;
  padding-left: 0;
  font-size: 15px;
  font-weight: 300;
  color: #333;
  background: transparent;
  box-sizing: border-box;
}
