.MuiPaginationItem-rounded {
  border-radius: 1px !important;
}

.MuiPaginationItem-outlined {
  border: 1px solid #259c8c !important;
}

.MuiPaginationItem-root {
  color: #259c8c !important;
}

.MuiPaginationItem-sizeLarge {
  height: 22px !important;
  padding: 12px 8px !important;
  font-size: 13px !important;
  min-width: 22px !important;
}

.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 20px !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #259c8c !important;
  color: white !important;
}

.MuiPagination-ul {
  float: none;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.MuiPagination-root {
  position: relative;
  margin: 20px auto;
}
