.label-container {
  text-align: center;
  margin: 0 auto;
}

.label {
  display: inline-flex;
  padding: 5px;
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  line-height: 18px;
  border: 1px solid transparent;
  border-radius: 5px;
  text-transform: uppercase;
  width: 100%;
  max-width: 120px;
}

.label-success {
  color: var(--label-color);
  border-color: var(--green-border);
}

.label-danger {
  color: var(--color-label-danger-text);
  border-color: var(--color-label-danger-border);
}

.label-warning {
  color: var(--color-label-warning-text);
  border-color: var(--color-label-warning-border);
}

.label-info {
  color: var(--color-label-info-text);
  border-color: var(--color-label-info-border);
}

@media (max-width: 768px) {
  .label-container {
    margin-bottom: 13px;
  }
}
