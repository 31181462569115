.token-placeholder-container {
  padding: 10px 20px;
}

.placeholder-box-container {
  background-color: #fff;
  border: 1px solid var(--border-color);
  margin: 25px;
  margin-bottom: 0;
  border-radius: 10px;
  padding: 10px 20px;
  height: 175px;
}

.token-placeholder-item {
  padding: 0 5px;
  margin: 10px auto;
}

.token-placeholder-container .token-placeholder-item:last-child {
  margin-bottom: 0;
}

.token-placeholder-container .token-placeholder-item:first-child {
  margin-top: 5px;
}

.token-placeholder-text {
  height: 10px;
  margin-bottom: 5px;
}

.placeholder-text,
.placeholder-text-1,
.placeholder-text-2,
.placeholder-text-3,
.placeholder-text-4,
.placeholder-text-5,
.placeholder-text-6 {
  height: 11px;
  margin-bottom: 10px;
}

.placeholder-text-1 {
  width: 90%;
}

.placeholder-text-2 {
  width: 75%;
}

.placeholder-text-3 {
  width: 60%;
}

.placeholder-text-4 {
  width: 50%;
}

.placeholder-text-5 {
  width: 40%;
}

.placeholder-text-6 {
  width: 30%;
}

.on-loading {
  animation: pulse 0.75s linear alternate infinite;
}

.placeholder-btn {
  display: inline-block;
  width: 50px;
  height: 20px;
  border-radius: 5px;
}

.category-placeholder-container {
  margin: 0 auto;
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px;
  padding-top: 0;
  max-width: 500px;
}

.category-placeholder-item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin: 0 auto;
  animation: pulse 0.75s linear alternate infinite;
}

.payouts-placeholder-container {
  height: 215px;
  padding: 30px 50px;
  margin: 25px;
  margin-bottom: 0;
  border-radius: 10px;
}

@media screen and (max-width: 400px) {
  .category-placeholder-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .category-placeholder-item {
    height: 60px;
    width: 60px;
  }
}

@media screen and (max-width: 768px) {
  .placeholder-box-container {
    margin: 0 15px;
    border-radius: 25px;
  }

  .category-placeholder-item {
    height: 60px;
    width: 60px;
  }

  .category-placeholder-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@keyframes pulse {
  0% {
    background-color: #ddd;
  }

  100% {
    background-color: #eee;
  }
}
