.not-found,
.not-found-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.not-found-section span {
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  display: block;
  border-bottom: 1px solid #eaeaea;
  text-align: center;
  padding-bottom: 20px;
  width: 100px;
}

.not-found-section p {
  font-size: 15px;
  font-weight: 400;
}

.not-found-section span + p {
  margin: 20px 0 0 0;
}

.not-found-back {
  cursor: pointer;
  font-size: 15px;
  margin-left: 5px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .not-found-section {
    height: 40px;
    flex-direction: row;
  }

  .not-found-section span,
  .not-found-section p {
    height: 100%;
    line-height: 40px;
  }

  .not-found-section span {
    border-bottom: 0;
    border-right: 1px solid #eaeaea;
    padding: 0 20px 0 0;
    width: auto;
  }

  .not-found-section span + p {
    margin: 0;
    padding-left: 20px;
  }
}
