.container {
  padding: 10px;
  background-color: var(--white);
  background-color: var(--white);
}

.fixed {
  position: fixed;
}

.greetings {
  animation: scaleUpHeroText 1.2s forwards;
  font-size: 25px;
  font-weight: var(--font-weight-bold);
  color: #333;
  text-align: center;
  margin: 10px auto;
  margin-top: 0;
}

label {
  font-size: 17px;
}

.flex {
  display: flex;
  flex-direction: row;
}

.button {
  background: var(--default-gray-color);
  color: var(--white);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
  font-size: 17px;
  width: 80%;
}

.select {
  width: 20%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.button,
.select {
  cursor: pointer;
  padding: 8px 11px;
  margin: 2px;
  text-align: center;
  background-color: #1ba1f2;
  color: var(--white);
}

.transactions-container {
  background: #f1f8fe;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 300px;
  padding: 30px;
  position: fixed;
  padding-bottom: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.fa-angle-down {
  color: var(--white);
}

span.price {
  float: right;
  color: grey;
}

.profile-icon {
  border-radius: 50%;
}

.column {
  float: left;
  padding: 10px;
  height: auto;
}

/* Clear floats after the columns */

.row:after {
  content: "";
  display: table;
  clear: both;
}

.two {
  text-align: right;
}

.one {
  text-align: left;
  width: 60%;
}

.one .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.two {
  width: 40%;
}

.amount {
  text-align: right;
  color: #17c261;
  font-size: 14px;
}

.icons {
  cursor: pointer;
  float: right;
  width: 24px;
  height: 24px;
}

.copyIcon {
  color: #000;
  cursor: pointer;
}

.username {
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 13px;
  font-weight: normal;
}

.profile-image {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
}

.transaction-description {
  font-size: 13px;
  color: #6b6b6b;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.wallet-balance {
  /* background-color: #e2f1f3; */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid var(--border-color-secondary);
  text-align: left;
  padding: 10px 20px;
}

.wallet-balance p {
  font-size: 15px;
}

.wallet-balance h1 {
  font-size: 22px;
}

.wallet-balance p,
h1 {
  margin: 0 auto;
}

main {
  margin: 0 auto;
}

.flex {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.flex-nav {
  height: 100%;
  background-color: var(--background);
  width: 100%;
  max-width: 300px;
}

.flex-left {
  transition: opacity 0.3s ease-out 0s;
  width: 100%;
}

.flex-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow: auto;
}

.inner-left {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 10px;
}

.inner-left a {
  color: #000;
}

.left-header {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color-secondary);
  text-align: left;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 0;
}

.header-border {
  border-bottom: 1px solid var(--border-color);
}

.left-header a {
  color: #000;
}

.left-header a:hover {
  color: #000 !important;
}

.inner-right {
  height: 100%;
}

.top-inner {
  text-align: left;
}

.top-inner p {
  font-size: 17px;
  font-weight: 600;
}

.top-inner h1 {
  font-size: 20px;
}

.show-transactions {
  display: none;
}

.hide-transactions {
  display: none;
}

.transactions-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #6b6b6b;
}

.show-transactions h2 {
  font-size: 15px;
  color: #1ba1f2;
}

span.price {
  float: right;
  color: grey;
}

.profile-icon {
  border-radius: 50%;
}

.column {
  float: left;
  padding: 10px;
  height: auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.two {
  text-align: right;
}

.one {
  text-align: left;
  width: 60%;
}

.one .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.two {
  width: 40%;
}

.slide-up-cta-container {
  display: none;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 38px;
}

.slide-up-cta-item {
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  background: #fff;
  padding: 0;
  font-weight: 600;
  border-radius: 7px;
  text-align: center;
  line-height: 1.5;
}

.slide-up-cta-item-left {
  cursor: pointer;
  border-right: 1px solid var(--border-color);
  width: 50%;
  padding: 12px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slide-up-cta-item-right {
  cursor: pointer;
  width: 50%;
  padding: 12px;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}

.slide-up-cta-item-left,
.slide-up-cta-item-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-hover:hover {
  background-color: #e2f1f3;
  color: var(--default-green-color);
}

.username {
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.transactions-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #6b6b6b;
}

button {
  border: none;
}

.slide-up-button {
  background: #f1f8fe;
  background: #f1f8fe;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 130px;
  padding: 0px;
  position: fixed;
  padding-bottom: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
}

.slide-up-button div h2 {
  color: #1ba1f2;
  font-size: 20px;
}

.slide-down-button {
  background: #f1f8fe;
}

.inner-transactions {
  border-bottom: 1px solid var(--border-color);
}

.category-cards-container {
  display: block;
  width: 100%;
}

.loader-container {
  margin: 20px auto;
  text-align: center;
  min-width: 200px;
}

.home-categories-container {
  box-sizing: border-box;
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 10px;
  overflow-x: scroll;
  width: 100%;
  align-items: center;
}

.home-page-activity {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 25px;
  padding: 25px;
}

.user-inbox-cta {
  display: none;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  color: rgb(37, 156, 140);
  margin: 30px;
  border-radius: 10px;
}

.user-inbox-cta svg {
  fill: rgb(37, 156, 140);
}

.user-inbox-cta-left {
  width: 100%;
}

.user-inbox-cta-right {
  text-align: right;
}

@media screen and (max-width: 770px) {
  .home-page-activity {
    padding: 20px;
  }

  .no-border {
    border: 0;
    background-color: transparent;
  }

  .flex {
    background-color: var(--white);
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }

  .flex-left {
    background-color: var(--white);
    display: block;
    width: 100%;
    overflow: auto;
    height: 100vh;
  }

  .flex-right {
    width: 100%;
  }

  .left-header-home {
    padding: 25px 20px;
  }

  .right-bottom {
    display: none;
    background: #f1f8fe;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 100px;
    padding: 30px;
    position: fixed;
    padding-bottom: 60px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .show-transactions {
    outline: none;
    background: #f1f8fe;
    background: #f1f8fe;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 130px;
    padding: 0px;
    position: fixed;
    padding-bottom: 30px;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .slide-up-button div h2 {
    color: #1ba1f2;
    font-size: 20px;
  }

  .slide-down-button {
    background: #f1f8fe;
  }

  .balance {
    margin-top: 0px;
  }

  .dropdown-content a:hover {
    background-color: #e8eaed;
  }

  .select:hover .dropdown-content {
    display: block;
  }

  .select:hover .dropbtn {
    background-color: #3e8e41;
  }

  .slider {
    height: 200px;
    border-style: solid;
    border-width: 5px;
    border-color: green;
    overflow: hidden;
    left: 0;
  }

  .abs {
    position: absolute;
  }

  .rel {
    position: relative;
  }

  .slider img {
    width: 100%;
  }

  .content {
    border: 2px solid blue;
  }

  .show-transactions h2 {
    font-size: 15px;
    color: #1ba1f2;
    margin: 0;
  }

  .hide-transactions {
    outline: none;
    background: #f1f8fe;
    position: fixed;
    left: 0;
    width: 100%;
    display: block;
  }

  .inner-left {
    margin-top: 0;
    border-radius: 0;
  }
}

/* Suggestion : let us have all media queries at the bottom */
@media screen and (max-width: 1200px) {
  .flex-nav {
    max-width: 125px;
    padding: 20px 0;
  }

  .sidebar-inner-top {
    border-radius: 10px;
    padding: 10px 5px;
  }
}

@media (max-width: 950px) {
  .flex-right {
    display: none;
  }

  .inner-left {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .transaction-container {
    display: block !important;
    padding: 10px 12px;
  }
}

@media screen and (min-width: 800px) {
  .transactions-container {
    height: 90%;
    width: 30%;
    right: 0;
    left: unset;
    background-color: var(--white);
    border-left: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    float: right;
    width: 30%;
  }

  .slide-down-button {
    display: none;
  }

  .linkpay-balance {
    padding: 25px;
    margin: 0;
    /* height: 200px;
          border-style: solid;
          border-width: 5px;
          border-color: green;
          overflow: hidden;
          left: 0; */
    /* left: unset; */
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--white);
    border-left: 1px solid var(--border-color);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    float: right;
    width: 30%;
  }
}

@media (max-width: 768px) {
  .slide-up-cta-container {
    display: block;
    padding: 0 15px;
    width: 100%;
  }

  .linkpay-form {
    margin: auto;
    margin: 0 auto;
    background: var(--white);
    margin-bottom: 15px;
  }

  .flex-nav {
    display: none;
  }

  .home-categories-container {
    background: var(--background);
  }

  .inner-left {
    display: block;
    position: relative;
    border-radius: 0;
    overflow: auto;
    margin: 0 auto;
  }

  .inner-left-home {
    background: var(--background);
    border: 0;
    height: calc(100vh - 63px);
    overflow: auto;
    padding-top: 0;
  }

  .user-inbox-cta {
    margin: 20px 15px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}
