html {
  --loader-line-color: var(--green);
  --loader-line-back-color: var(--green-bg-light);
  --loader-line-time: 2s;
  --loader-line-size: 3px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  opacity: 0.45;
}

.loader-line {
  background-color: var(--loader-line-color);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  z-index: 9;
  width: 100%;
}

.loader-line-container {
  height: var(--loader-line-size);
  width: 100%;
  background: var(--loader-line-back-color);
}

.loader-line-container:before {
  content: "";
  display: block;
  background-color: var(--loader-line-color);
  height: var(--loader-line-size);
  width: 0;
  animation: getWidth var(--loader-line-time) ease-in infinite;
}

.loader-page {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}
