.inbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(217, 255, 224);
  background: linear-gradient(
    90deg,
    rgba(217, 255, 224, 1) 0%,
    rgba(234, 234, 238, 1) 100%
  );
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
}

.inbox-no-requets {
  font-size: 16px;
  margin: 10px 0;
}

.inbox-title {
  font-size: 18px;
  font-weight: bold;
}

.alert-text {
  font-size: 16px;
  margin: 20px 0;
  margin-bottom: 15px;
  text-align: left;
  display: flex;
  align-items: center;
}

.btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-text-error {
  color: var(--red-color);
}

.alert-text-success {
  color: var(--green-color);
}

.inbox-container button {
  cursor: pointer;
  text-transform: capitalize;
}

.inbox-container button img {
  height: 20px;
}

.inbox-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inbox-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0;
  height: 28px;
  width: 28px;
}

.inbox-buttons button:hover {
  background-color: var(--green-color);
  color: var(--white);
  border-radius: 50%;
  padding: 2px;
}

.inbox-buttons button:first-child {
  margin-right: 10px;
}

.inbox-row {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.inbox-row button {
  background-color: #fff;
  border-radius: 50%;
}

.grey-text {
  color: #757171;
}

.wait-button {
  margin-right: var(--paybtn-margin-right);
  padding: var(--paybtn-padding);
  border-radius: var(--paybtn-border-radius);
  color: var(--white);
  background-color: var(--green-color);
  border: var(--paybtn-border);
  font-size: var(--paybtn-font-size);
  max-width: var(--paybtn-max-width);
  text-transform: var(--paybtn-text-transform);
  width: 100%;
}

.inbox-loading {
  display: flex;
  align-items: center;
  margin: 20px auto;
  margin-bottom: 15px;
}

.inbox-loading-label {
  align-items: center;
  display: flex;
  margin-left: 10px;
  width: 100%;
}

.inbox-request-label {
  font-size: 17px;
  line-height: 1.5;
  padding: 0;
  margin: 15px 0;
}

@media (max-width: 768px) {
  .inbox-container {
    margin: 0 auto;
    background-color: var(--white);
    padding: 20px;
    margin-top: -25px;
    border-radius: 10px;
  }

  .inbox-request-label {
    font-size: 14px;
    margin: 8px 0;
  }

  .grey-text {
    font-size: 13px;
  }

  .inbox-container h2 {
    font-size: 18px;
  }

  .inbox-no-requets {
    margin: 0 auto;
    margin-top: 3px;
    font-size: 15px;
    width: 100%;
  }
}
