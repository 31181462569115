.user-summary {
  background-color: var(--white);
  position: relative;
  border: 9;
  border-bottom: 1px solid var(--border-color-secondary);
  margin: 0 auto;
  margin-bottom: 10px;
  top: 20px;
  border-radius: 10px 10px 0 0;
}

.side-top {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
}

.side-avatar {
  margin-right: 10px;
  border: 0.8px solid var(--green-color);
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green-color);
  color: #fff;
  font-weight: 600;
}

.side-name {
  font-size: 17px;
  color: var(--gray-color);
  font-weight: 600;
}

.side-email {
  display: flex;
  align-items: center;
  color: rgb(101, 119, 134);
  flex-direction: row;
  font-size: 13.5px;
  margin: 2px 0;
}

.side-email-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: var(--green-color);
}

.side-email-text {
  color: #222;
  margin-left: 5px;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  max-width: 150px;
  font-size: 14px;
}

.side-email-text:hover {
  color: var(--green-color);
}

.inner-nav {
  /* padding: 0 20px;
    height: 100%;
    margin: 0 auto; */
  height: 100%;
  margin: 0 auto;
  margin-right: 20px;
  margin-left: 10px;
}

.main-sidebar {
  max-width: 280px;
  width: 100%;
  height: 100%;
}

.sidebar-inner {
  border: 0;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  position: fixed;
  max-width: 280px;
  height: 100%;
  width: 100%;
  z-index: 8;
}

.sidebar-inner-top {
  padding: 10px 5px;
  padding-top: 30px;
  background-color: var(--white);
  border-radius: 0 0 10px 10px;
}

.sidebar-inner-bottom {
  padding: 10px 0;
}

.active .navigate-inner {
  /* background-color: #e2f1f3; */
  border-radius: 30px;
  border: 0;
  border-left: 0;
  color: var(--green-color);
}

.navigate {
  /* background-color : var(--white); */
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  padding: 6px 10px;
}

.navigate-inner {
  color: var(--gray-color);
  justify-content: center;
  border-radius: 25px;
  padding: 10px 20px;
}

.navigate-inner:hover {
  /* background-color: #e2f1f3; */
  color: var(--green-color);
}

.navigate,
.navigate-inner {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.label-text {
  margin-left: 10px;
}

.add,
.view {
  border-top: 0px solid #e8eaed;
  border-top: 0px solid var(--border-color-primary);
}

.verify-email {
  border-top: 1px solid var(--border-color);
  font-size: 14px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: var(--yellow-20);
}

.verify-email button {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  outline: none;
  padding: 5px;
  width: 90px;
  border-radius: 25px;
  justify-content: center;
  border: 0;
  color: var(--white);
  background-color: var(--text-color-success);
}

.verify-email span {
  width: 100%;
  margin-right: 5px;
}

@media (max-width: 1200px) {
  .label-text,
  .user-summary {
    display: none;
  }
}
