:root {
  --tb-bg: #f9f9f9;
  --tb-white: #fff;
  --tb-border: #e1e4e8;
  --tb-green: #1cbc96;
  --tb-color: #000;
}

.table {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  color: var(--tb-color);
}

.tb-main {
  margin: 0 auto;
  max-width: 1000px;
  overflow: auto;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 20px;
}

.table thead {
  background: var(--tb-bg);
}

.table thead tr {
  border-bottom: 1px solid var(--tb-border);
}

.table th,
td {
  padding: 15px 20px;
  min-width: 100px;
  user-select: none;
  text-align: left;
}

.table td {
  padding: 8px 20px;
  font-size: 12.5px;
  background-color: var(--tb-white);
}

.table tr {
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 6%);
  border-bottom: 1px solid var(--tb-border);
  line-height: 1.5;
  font-size: 15px;
}

.tb-empty {
  background-color: var(--tb-bg);
  text-align: center;
  padding: 20px;
}

.tb-tr-placeholder {
  height: 20px;
}

.tb-tr-placeholder td {
  margin-bottom: 10px;
}

.tb-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 20px;
}

.tb-pagination-btns {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tb-pagination-prev-btn,
.tb-pagination-next-btn {
  border: 1px solid var(--tb-border);
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 15px;
}

.tb-pagination-btn-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tb-border);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  cursor: pointer;
}

.tb-pagination-prev-btn:hover,
.tb-pagination-next-btn:hover,
.tb-pagination-btn-item:hover {
  background-color: var(--tb-green);
  color: var(--tb-white);
}

.tb-pagination-btn-item-selected {
  color: var(--tb-white);
  background-color: var(--tb-green);
}

.tb-skeleton {
  background-color: var(--tb-bg);
  padding: 10px 10px !important;
}

.tb-skeleton-cell {
  border-radius: 2px;
  height: 30px;
  animation: pulse 0.75s linear alternate infinite;
}

@keyframes tb-pulse {
  0% {
    background-color: #ddd;
  }

  100% {
    background-color: #eee;
  }
}
