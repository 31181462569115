.category-card-row {
  border: 0;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px,
    rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

.category-card-img {
  object-fit: cover;
  width: 60px;
  margin: 10px;
}

.category-card-row-right {
  width: 100%;
  font-size: 20px;
  padding-left: 15px;
}

.category-card-row-left {
  border: 0;
  border-right: 1px solid var(--border-color);
  display: flex;
  align-items: center;
}

.category-card-row-icon {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
