.account-page,
.update-account {
  height: 100%;
}

.account .links-right:after {
  pointer-events: none;
  position: relative;
  float: right;
  content: url("../../../assets/images/chevron-right.svg");
  margin-top: 0.5em;
  font-size: 1.2em;
  transition: 0.5s;
}

.account .inner-paylink {
  border-bottom: 1px solid var(--border-color);
  padding: 20px 20px;
  line-height: 10px;
}

.account .links-left {
  width: 90%;
  color: var(--gray-color);
  font-size: 17px;
}

.links-left {
  width: 90%;
  color: var(--gray-color);
}

.flex-links {
  display: flex;
  flex-direction: row;
}

.footer-text {
  color: rgb(101, 119, 134);
  cursor: pointer;
  font-weight: normal;
  font-size: 15px;
}

@media (max-width: 768px) {
  .account-page .right-top {
    display: none;
  }
}
