.auth-page,
.auth-page-right {
  display: flex;
  align-items: center;
}

.auth-page {
  height: 100vh;
  width: 100%;
}

.auth-pahe h1,
.auth-page h2,
.auth-page p {
  padding: 0;
  margin: 0;
}

.auth-page-left {
  background: rgb(240, 242, 247);
  background-image: url("../../assets/images/pattern-randomized.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  height: 100vh;
  width: 40%;
}

.auth-page-right {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  height: 100vh;
  padding: 0 5rem;
}

.auth-form,
.auth-confirm {
  max-width: 500px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.auth-form-phone-title {
  font-weight: 600;
}

.auth-form-header-title {
  font-size: 1.8rem;
  font-weight: 500;
}

.auth-form-header-subtitle {
  font-size: 1.2rem;
  font-weight: 400;
  color: #6c757d;
  margin: 5px 0;
  margin-bottom: 8px;
}

.auth-form-footer-text {
  font-size: 1.1rem;
  color: #6c757d;
}

.auth-form-group {
  margin-bottom: 1rem;
}

.auth-form-group input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.9rem 0.75rem;
  font-size: 15px;
  width: 100%;
}

.auth-form-group input:focus {
  border-color: #44bb97;
}

.auth-form-group input::placeholder {
  color: #6c757d;
  font-size: 0.9rem;
}

.auth-form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.3rem;
}

.auth-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #44bb97;
  border: 1px solid #44bb97;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.6rem 0.75rem;
  max-width: 200px;
  min-width: 120px;
}

.auth-form-btn:hover {
  background: #3aa17f;
  border: 1px solid #3aa17f;
}

.auth-form-btn:disabled {
  background: #6c757d;
  border: 1px solid #6c757d;
  cursor: not-allowed;
}

.auth-btn-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.auth-form-btn-cancel {
  background: #313232;
  border: 1px solid #313232;
  color: #fff;
}

.auth-form-btn-cancel:hover {
  background: #1f1f1f;
  border: 1px solid #1f1f1f;
}

.invalid-input-label,
.auth-alert-text {
  color: #dc3545;
  font-size: 14px;
  margin-top: 2px;
}

.auth-form-footer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.auth-form-footer a,
.auth-link {
  cursor: pointer;
  color: #44bb97;
  font-weight: 500;
  text-decoration: underline;
}

.auth-alert,
.auth-alert-icon {
  display: flex;
  align-items: center;
}

.auth-alert {
  justify-content: space-between;
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: #f8d7da;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin: 1rem 0;
  font-size: 0.9rem;
  width: 100%;
}

.auth-alert-icon {
  cursor: pointer;
  justify-content: center;
}

.auth-form-terms {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 1rem;
}

.auth-otp-label {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
  .auth-page {
    flex-direction: column;
  }

  .auth-page-left,
  .auth-page-right {
    width: 100%;
  }

  .auth-page-left {
    height: 30%;
  }

  .auth-page-right {
    padding: 20px;
    align-self: start;
    justify-content: flex-start;
    flex: 1;
  }

  .auth-form-header-subtitle {
    margin-bottom: 10px;
  }
}
