.search-container {
  border: 0;
  border: 2px solid #d8eee0;
  padding: 4px 20px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
}

.search-icon {
  padding: 0;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
}

.search-icon svg {
  fill: var(--green-color);
}

.search-input {
  outline: 0;
  border: 0;
  background: transparent;
  font-weight: 400;
  font-size: 15px;
  padding: 4px 0;
  width: 100%;
  color: var(--green-color);
}

.search-input::placeholder {
  color: var(--green-color);
  opacity: 1;
}

@media screen and (min-width: 800px) {
  .search-container {
    padding: 6px 20px;
    font-size: 17px;
    max-width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .search-container {
    padding: 2px 15px;
  }
  .search-input {
    font-size: 15px;
    padding: 10px 0;
  }
}
