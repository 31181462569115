.user-name {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin: 10px auto;
}

.user-bio {
  text-align: center;
  padding: 0 15px;
  margin: 0 auto;
}

.create-title {
  display: none;
  font-size: 18px;
  margin: 10px 0;
}

.title-two {
  font-size: 18px;
  margin: 10px 0;
  font-weight: 600;
}

.profile-input {
  font-size: 16px;
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.profile-form {
  padding: 20px;
}

.text-holder {
  margin-left: 5px;
}

.account-page-form {
  padding: 0;
}

.account-item {
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-color-secondary);
  padding: 10px 20px;
  line-height: 1.5;
}

.account-item:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.account-item-left {
  width: 100%;
}

.account-item-right {
  color: var(--gray-color);
  text-align: right;
  width: 40px;
}

.account-name {
  color: #657786;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.account-input {
  display: block;
  font-size: 15px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-bottom: 17px;
}

.account-input:focus {
  border: 1px solid var(--green-color);
}

.account-label {
  color: var(--gray-color);
  font-size: 17px;
  margin: 0;
}

.account-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #44bb97;
  border: 1px solid #44bb97;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.6rem 0.75rem;
  max-width: 200px;
  min-width: 120px;
}

.account-btn:hover {
  background: #3aa17f;
  border: 1px solid #3aa17f;
}

.account-btn:disabled {
  opacity: 0.9;
  background-color: #ddd;
  border: 1px solid var(--border-color);
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.account-page-container {
  background-color: var(--white);
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

.account-page-flexbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
}

.account-page-back {
  padding-left: 10px;
}

span .left-h1 {
  padding: unset;
}

.fa-arrow-left {
  color: #1ba1f2;
}

.back-arrow {
  color: #657786;
  margin-right: 5px;
}

.label-container-top {
  display: none;
  width: 100%;
}

@media (max-width: 768px) {
  .account {
    background-color: var(--white);
  }
  .account-input {
    padding: 10px;
  }

  .update-account .right-top {
    display: none;
  }

  .account-item:last-child {
    border-bottom: 1px solid var(--border-color-secondary);
    border-radius: 0;
  }
}

@media (max-width: 800px) {
  .label-container-top {
    display: block;
    text-align: right;
  }

  .left-h1 {
    text-align: left;
    margin: 0;
  }

  .left-header-home {
    display: flex;
    align-items: center;
  }
  .left-header .label {
    text-align: center;
    padding: 4px 10px;
    align-items: center;
  }
}
