.transactions {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin: 0;
}

.tx-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f8f9fa;
}

.tx-header-title,
.transactions-header-count {
  font-size: 12px;
  text-transform: capitalize;
}

.transactions-header-count {
  font-weight: 500;
}

.tx-item {
  color: #393a3c;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 20px;
  font-size: 12px;
}

.tx-item-cell {
  display: flex;
  gap: 3px;
  flex-direction: column;
  flex: 1;
}

.tx-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #caecff;
  color: #007bff;
  border-radius: 10px;
  max-width: 30px;
  height: 30px;
  flex: 1;
}

.tx-object {
  gap: 10px;
  align-items: flex-start;
  flex-direction: row;
}

.tx-status {
  border: 1px solid #007bff;
  padding: 2px 5px;
  text-transform: capitalize;
  font-size: 11px;
  text-align: center;
  border-radius: 5px;
  max-width: 80px;
}

.tx-token {
  color: #6c757d;
  cursor: pointer;
}

.tx-token-copy {
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  color: #000;
  gap: 3px;
  font-size: 10px;
  color: #6c757d;
  border: 0.5px solid #e5e5e5;
  border-radius: 5px;
  padding: 3px 5px;
  margin-top: 5px;
}

.tx-object-name {
  font-weight: 600;
}

.tx-object-id {
  font-size: 10px;
  color: #6c757d;
}
