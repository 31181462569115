.header {
  background-color: var(--white);
  border-bottom: 1px solid var(--border-color);
  margin: 0 auto;
  padding: 10px 8px;
  position: sticky;
  top: 0;
  z-index: 10001;
  display: none;
}

.top-nav {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  max-width: 900px;
}

.nav-left,
.nav-right {
  display: flex;
  width: 50%;
  align-items: center;
}

.nav-show {
  width: 100%;
  text-align: right;
  color: #316b72;
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  .header {
    display: none;
  }
}
