.bottom-menu {
  display: none;
  background: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  padding: 54px 0;
  z-index: 3;
}

.modal-h1 {
  font-size: 19px;
  font-weight: var(--font-weight-bold);
  color: #1ba1f2;
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color);
}

@media screen and (max-width: 768px) {
  .flex-links,
  .payment-note {
    font-size: 15px;
  }

  .wallet-nav-item {
    padding: 15px 20px !important;
    background-color: var(--white);
  }

  .wallet-nav-item:last-child {
    border-bottom: 1px solid var(--border-color-secondary);
  }

  .link-items {
    overflow: auto;
    height: 100%;
  }

  .left-h1 {
    padding: 0px;
  }

  .account-page-flexbox {
    padding: 3px !important;
  }

  .redeem-section {
    border: 0;
    padding: 15px;
    border-bottom: 0px solid var(--border-color-primary);
    border-radius: 5px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }

  .lockpin {
    font-size: 13px;
  }

  .reclaim-link,
  .reclaim-btn,
  .state-label {
    font-size: 10px;
    text-align: center;
  }

  .top-inner p {
    font-size: 15px;
  }

  .redeem-btn {
    padding: 10px 0 !important;
    font-size: 16px;
  }

  .confirm-alert-body {
    width: 100% !important;
    padding: 15px 20px !important;
    min-width: 325px;
  }

  .link-control input {
    padding: 8px 10px;
  }
}

@media screen and (min-width: 800px) {
  .bottom-menu {
    display: none;
  }
  .mobile {
    display: none !important;
  }
}
